import { useEffect, useState } from 'react';
import { IconList } from '@tabler/icons';
import { Alert, Box, Chip, CircularProgress, IconButton, useTheme } from '@material-ui/core';
import { useGetMeshQuery } from '../../../maintenance/meshs/slices/meshsApiSlice';
import {
  useGetStudentPeriodInfoRegisterSubjectsQuery,
  useGetSubjectsCurriculumStatusQuery,
} from '../slices/subjectsRegisterApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import Button from '@material-ui/core/Button';
import ToggleButtonGroup from '@material-ui/core/ToggleButtonGroup';
import ToggleButton from '@material-ui/core/ToggleButton';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import Grid from '@material-ui/core/Grid';
import MainCard from '../../../shared/components/cards/MainCard';
import RegisterByCurriculumDialog from '../components/mallas/RegisterByCurriculumDialog';
import Simbologies from '../components/mallas/Symbologies';
import HourshandRegistered from '../components/horarios/HourshandRegistered';
import DeleteCourseStudentDialog from '../components/DeleteCourseStudentDialog';
import RegisterByHourhandDialog from '../components/horarios/RegisterByHourhandDialog';
import InfoSubjectDialog from '../components/horarios/InfoSubjectDialog';
import { IconArrowNarrowLeft } from '@tabler/icons';

import {
  fireDialogSubjectsOnline,
  fireDialogSubjectsRegistered,
  resetSubjectsRegisterState,
  setEducationLevelIdByStudentSelected,
  setIsOnline,
  setIsPayment,
  setShowCurriculumAssignModal,
  setShowHourhandAssignModal,
  setShowInfoSubjectModal,
  setSubjectsCurriculum,
  setUnavailableRegisterDialog,
} from '../slices/subjectsRegisterSlice';
import useStudentInfo from '../hooks/useStudentInfo';
import PreProcessRegisterScreen from './PreProcessRegisterScreen';
import { useGetCurrentPeriodQuery } from '../../../maintenance/periods/slices/periodsApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetStudentRecordsByStudentQuery,
  useLazyGetStudentQuery,
} from '../../../maintenance/students/slices/studentsApiSlice';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import MeshContainer from '../components/mallas/MeshContainer';
import PreregisterScreen from '../components/preregister/PreregisterScreen';
import { setStudentsList } from '../../../maintenance/students/slices/studentsSlice';
import SubjectsRegisterOnline from '../components/online/SubjectsRegisterOnline';
import { useTranslation } from 'react-i18next';
import NotAvailableRegisterDialog from '../components/NotAvailableRegisterDialog';

type View = 'horario' | 'malla';

const SubjectsRegisterScreen = () => {
  const { t } = useTranslation();

  const { studentId } = useParams<{ studentId: string }>();
  const [view, setView] = useState<View>('malla');

  const [registerActive, setRegisterActive] = useState(false);
  const studentInfo = useStudentInfo();

  const { currentProfile, currentRecordStudent, currentStudent } = useAppSelector(
    (state) => state.access
  );

  const { studentsList } = useAppSelector((state) => state.students);

  const [getStudent] = useLazyGetStudentQuery();
  const student = studentsList.find((student) => student.id === Number(studentId));

  useEffect(() => {
    if (!student) {
      getStudent({
        profileId: currentProfile?.profileId!,
        studentId: Number(studentId),
      })
        .unwrap()
        .then((response) => {
          dispatch(setStudentsList([response!]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(resetSubjectsRegisterState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (studentInfo?.offerName === 'Online') {
      dispatch(setIsPayment(true));
      dispatch(setIsOnline(true));
    } else {
      dispatch(setIsPayment(false));
      dispatch(setIsOnline(false));
    }
  }, [studentInfo, dispatch]);

  const {
    showSubjectsOnline,
    showCurriculumAssignModal,
    showHourhandAssignModal,
    showInfoSubjectModal,
    currentHourhand,
    isPayment,
    isOnline,
    currentPeriodId,
    unavailableRegisterDialog,
  } = useAppSelector((state) => state.subjectsRegister);

  const {
    currentSubject,
    courseStudentIdToDelete,
    currentSubjectsRegistered,
    subjectStatusIdSelected,
  } = useAppSelector((state) => state.subjectsRegister);

  //Carga el student record desde el admin
  const { data: studentRecordsAvaliables = [], isLoading: isLoadingER } =
    useGetStudentRecordsByStudentQuery(
      { studentId },
      { refetchOnMountOrArgChange: 30, skip: !studentId }
    );

  //Carga de periodos y studentRecords
  const { data: periodsAvailables = [], isLoading: isLoadingPeriods } = useGetCurrentPeriodQuery(
    {
      profileId: currentProfile?.profileId!,
      offerId: studentInfo?.offerId!,
      current: true,
      isAsignature: true,
    },
    { skip: !studentInfo }
  );

  //Carga de malla
  const { data, isLoading } = useGetMeshQuery(
    {
      meshId: studentInfo?.curriculumId!,
      profileId: currentProfile?.profileId!,
    },
    { skip: !studentInfo }
  );

  useEffect(() => {
    if (data) dispatch(setSubjectsCurriculum(data.matter_mesh));
  }, [dispatch, data]);

  const {
    data: materiasAprobadas,
    isLoading: isLoadingMateriasAprobadas,
    isFetching: isFetchingMateriasAprobadas,
  } = useGetSubjectsCurriculumStatusQuery(
    {
      studentRecordId: studentInfo?.studentRecordId!,
      period_id: currentPeriodId,
      ...(subjectStatusIdSelected && {
        status_subject: subjectStatusIdSelected,
      }),
    },
    { refetchOnMountOrArgChange: 30, skip: !studentInfo || !currentPeriodId }
  );

  const { data: infoStudentPeriodSubjects } = useGetStudentPeriodInfoRegisterSubjectsQuery(
    {
      studentId: studentId ? Number(studentId) : currentStudent?.id!,
      periodId: currentPeriodId!,
    },
    {
      skip: !currentPeriodId || (!studentId && !currentStudent?.id) || !currentProfile,
    }
  );

  useEffect(() => {
    if (studentRecordsAvaliables.length > 0) {
      dispatch(
        setEducationLevelIdByStudentSelected(studentRecordsAvaliables[0].education_level_id)
      );
      setRegisterActive(true);
    }
    if (periodsAvailables.length > 0) setRegisterActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentRecordsAvaliables, periodsAvailables]);

  return (
    <>
      <Grid
        component={MainCard}
        sx={{
          pb: 4,
        }}
      >
        <Grid item sx={{ mb: 3 }} container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Stack spacing={1}>
              <Typography variant="h2">
                {studentId && (
                  <IconButton
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
                  </IconButton>
                )}
                {t('students.subjects.registration.title')}{' '}
                {student?.pers_full_name ? ` - ${student?.pers_full_name}` : ''}
              </Typography>
              <Typography variant="body1">
                {t('students.subjects.registration.description')}
              </Typography>
              <Typography variant="h4">
                {currentRecordStudent?.education_level?.edu_name}
              </Typography>
            </Stack>
          </Grid>
          {!isOnline && isPayment && (
            <Grid item>
              <ToggleButtonGroup
                value={view}
                onChange={(_, value) => setView(value as View)}
                aria-label="Tipo de vista para el registro de asignaturas"
                color="primary"
                exclusive
              >
                <ToggleButton value="malla" aria-label="left aligned">
                  <Typography>{t('students.subjects.registration.view.curriculum')}</Typography>
                </ToggleButton>
                <ToggleButton value="horario" aria-label="centered">
                  <Typography>{t('students.subjects.registration.view.schedule')}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 3 }}>
          <Grid item xs={12} md={12} container>
            <Grid item>
              <Simbologies
                simbologies={[
                  { label: t('students.subjects.registration.label.approved'), color: '#00AAF5' },
                  {
                    label: t('students.subjects.registration.label.currently_undertaken'),
                    color: '#ffe57f',
                  },
                  {
                    label: t('students.subjects.registration.label.registered'),
                    color: '#608b9fd9',
                  },
                  {
                    label: t('students.subjects.registration.label.available'),
                    color: '#00C853',
                  },
                  {
                    label: t('students.subjects.registration.label.unavailable'),
                    color: '#D84315',
                  },
                  { label: t('students.subjects.registration.label.failed'), color: '#2c221fc7' },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <Alert severity="info">
                <Typography>{t('students.subjects.registration.info.process_title')}</Typography>
                <Typography>
                  <ol>
                    <li>{t('students.subjects.registration.info.process_1')}</li>
                    <li>{t('students.subjects.registration.info.process_2')}</li>
                    <li>{t('students.subjects.registration.info.process_3')}</li>
                  </ol>
                </Typography>
              </Alert>
            </Grid>
          </Grid>
          {isPayment && currentPeriodId && !isOnline && (
            <Grid item xs={12} md={4} container justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                startIcon={<IconList size={20} />}
                endIcon={
                  currentSubjectsRegistered ? (
                    <Chip
                      label={currentSubjectsRegistered.length}
                      sx={{ color: '#ffff', fontSize: 'small' }}
                    />
                  ) : null
                }
                onClick={() => dispatch(fireDialogSubjectsRegistered(true))}
              >
                {t('students.subjects.registration.registered_subjects')}
              </Button>
            </Grid>
          )}
          {infoStudentPeriodSubjects && (
            <>
              {infoStudentPeriodSubjects.num_course_student >=
                infoStudentPeriodSubjects.num_min_period &&
              infoStudentPeriodSubjects.num_course_student <=
                infoStudentPeriodSubjects.num_max_period ? (
                <Grid item xs={12} md={8} container justifyContent="flex-end" alignItems="center">
                  <Typography variant="body1">
                    {t('students.subjects.registration.registered_subjects.info_base')}
                    <b style={{ color: theme.palette.success.main }}>
                      {infoStudentPeriodSubjects.num_course_student}
                    </b>
                    {t('students.subjects.registration.registered_subjects.info_max', {
                      total: infoStudentPeriodSubjects.num_max_period,
                    })}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} md={8} container justifyContent="flex-end" alignItems="center">
                  <Typography variant="body1">
                    {t('students.subjects.registration.registered_subjects.info_base')}
                    <b style={{ color: theme.palette.error.main }}>
                      {infoStudentPeriodSubjects.num_course_student}
                    </b>
                    {t('students.subjects.registration.registered_subjects.info', {
                      min: infoStudentPeriodSubjects.num_min_period,
                      total: infoStudentPeriodSubjects.num_max_period,
                    })}
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>

        {isLoadingER || isLoadingPeriods ? (
          <Grid container alignItems="center" justifyContent="center" height="200px">
            <CircularProgress />
          </Grid>
        ) : registerActive ? (
          <PreProcessRegisterScreen
            periodsAvailables={periodsAvailables}
            isLoadingPeriods={isLoadingPeriods}
            isLoadingER={isLoadingER}
            studentRecordsAvaliables={studentRecordsAvaliables}
          />
        ) : (
          <Box>
            <Grid container justifyContent="center" alignContent="center">
              <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
                <Grid container item xs={12} justifyContent="center">
                  <IconTelework size={300} />
                </Grid>
                <Grid item>
                  <Typography variant="body1" textAlign="center" lineHeight={2} component="div">
                    <Typography
                      variant="h3"
                      sx={{ color: theme.palette.primary.main }}
                      component="p"
                      align="center"
                      lineHeight={2}
                    >
                      {t('students.subjects.registration.warning')}
                    </Typography>
                    {t('students.subjects.registration.no_data')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        {!isPayment && !isOnline && <PreregisterScreen />}

        {isPayment && (
          <>
            {view === 'malla' ? (
              <MeshContainer
                subjectCurriculums={data?.matter_mesh}
                subjectCurriculumsStatus={materiasAprobadas!}
                isLoading={isLoading}
                isLoadingMateriasAprobadas={
                  isLoadingMateriasAprobadas || isFetchingMateriasAprobadas
                }
                curriculum_new={data?.curriculum_new}
              />
            ) : (
              <HourshandRegistered />
            )}
          </>
        )}
      </Grid>

      {unavailableRegisterDialog?.open && (
        <NotAvailableRegisterDialog
          open
          handleCloseDialog={() => {
            dispatch(setUnavailableRegisterDialog({ open: false, message: '' }));
          }}
        />
      )}

      {currentSubject && (
        <RegisterByCurriculumDialog
          open={showCurriculumAssignModal}
          handleCloseDialog={() => dispatch(setShowCurriculumAssignModal(false))}
        />
      )}

      {currentHourhand && (
        <RegisterByHourhandDialog
          open={showHourhandAssignModal}
          handleCloseDialog={() => dispatch(setShowHourhandAssignModal(false))}
        />
      )}

      {courseStudentIdToDelete && <DeleteCourseStudentDialog />}

      {showInfoSubjectModal && (
        <InfoSubjectDialog
          open={showInfoSubjectModal}
          handleCloseDialog={() => dispatch(setShowInfoSubjectModal(false))}
        />
      )}

      <SubjectsRegisterOnline
        open={showSubjectsOnline}
        handleCloseDialog={() => dispatch(fireDialogSubjectsOnline(false))}
      />
    </>
  );
};

export default SubjectsRegisterScreen;
